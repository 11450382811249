<template>
    <div class="plan_for_you">
        <div class="plan_for_you_title">
            <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/plan_logo.webp`" alt="" class="logo">
            {{ $t('ProCustomizedPlan_PlanFor_ItemTitle', { name: username }) }}
        </div>
        <div class="plan_list">
            <div class="banner_swipe">
                <div class="plan-swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in planList" :key="index">
                            <div class="title">
                                {{ $t('ProCustomizedPlan_Stage_ItemTitle', {
                                    stage: `${index + 1}: ${index * 4 + 1}-${(index + 1) * 4} `
                                }) }}
                            </div>
                            <div class="describe">
                                <div class="describe_left">
                                    <div v-for="(ite, ind) in item.stageTextList" :key="ind">
                                        <span v-for="(it, i) in ite" :key="i" :class="{ 'bold_text': it.isBold }">
                                            {{ it.content }}
                                        </span>
                                    </div>
                                </div>
                                <div class="describe_right">
                                    <div class="lose_weight">
                                        <span class="stage_weight">{{ item.stageWeight }}</span>
                                        <span class="stage_unit"> {{ isMetric ? 'kg' : 'lbs' }}</span>
                                    </div>
                                    <div class="stage_num">
                                        {{ $t('ProCustomizedPlan_GoalOfStage_ItemTitle') }} {{ index + 1 }}
                                    </div>
                                    <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/arrow_target.webp`"
                                        alt="arrow_target" class="arrow_target">
                                </div>
                            </div>
                            <div class="plan_info">
                                <loading-image :imageUrl="item.imgUrl"></loading-image>
                                <div class="first_plan" v-if="index == 0">
                                    <div class="cookbook_table" v-for="(ite, ind) in weekInfo" :key="ind">
                                        <div class="cookbook_table_inner">
                                            <div class="cookbook_table_date">
                                                Day{{ ite.cron_week_day }}
                                            </div>
                                            <div class="cookbook_table_fast">
                                                <span v-if="!ite.is_rest_day">
                                                    Fast {{ ite.fasting_hour }}:{{ ite.eating_hour }}
                                                </span>
                                            </div>
                                            <div class="cookbook_table_food" v-if="!ite.is_rest_day">
                                                <img @error="imageError" v-for="(it, i) in ite.mealList" :key="i"
                                                    :src="idToMeal(it.recipes_id)">
                                            </div>
                                            <div class="cookbook_table_food_rest" v-else>
                                                <span>{{ $t('TrackerPopup_CheatDay_DialogText1') }}</span>
                                                <img @error="imageError" class="rest" alt="rest"
                                                    :src="`${$store.state.assetsUrl}/imgs/plan/rest.webp`">
                                            </div>
                                            <div class="cookbook_table_drink" v-if="!ite.is_rest_day">
                                                <img @error="imageError" width="100%" alt="run"
                                                    :src="`${$store.state.assetsUrl}/imgs/plan/drink.webp`">
                                            </div>
                                            <div class="cookbook_table_run"
                                                v-if="!ite.is_rest_day && ite.exercise_type != ''">
                                                <img @error="imageError" width="100%" alt="run"
                                                    :src="`${$store.state.assetsUrl}/imgs/plan/run.webp`">
                                            </div>
                                        </div>
                                        <div class="cookbook_table_line" v-if="ind != weekInfo.length - 1"></div>
                                    </div>
                                </div>
                                <div class="cookbook_table_describe">
                                    <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/lamp.webp`"
                                        alt="lamp">
                                    <span>
                                        {{ $t('CustomFastingPlan_StageOverviewTips_ItemText') }}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- 如果需要分页器 -->
                    <div class="swiper_pagination" v-if="planList.length > 0">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import LoadingImage from '../loading_image.vue';
import { common } from '@/assets/utils/common.js';
import Swiper from 'swiper';
import 'swiper/swiper.css';
import { commonMixin } from '../../assets/mixin/common';
export default {
    mixins: [commonMixin],
    name: 'plan_for_you',
    components: {
        LoadingImage
    },
    data() {
        return {
            planList: [],
            stages: {
                stage1: [
                    this.$t('PlanStage1Text1'),
                    this.$t('PlanStage1Text2'),
                    this.$t('PlanStage1Text3'),
                ],
                stage2: [
                    this.$t('PlanStage2Text1'),
                    this.$t('PlanStage2Text2'),
                ],
                stage3: [
                    this.$t('PlanStage3Text1'),
                    this.$t('PlanStage3Text2'),
                ],
                stage4: [
                    this.$t('PlanStage4Text1'),
                    this.$t('PlanStage4Text2'),
                ],
                stage5: [
                    this.$t('PlanStage5Text1'),
                    this.$t('PlanStage5Text2'),
                ],
                targetStage: [
                    this.$t('PlanStageMoreText1'),
                    this.$t('PlanStageMoreText2'),
                ],
            },
            planBlackText: [
                this.$t('PlanStageBlackText1'),
                this.$t('PlanStageBlackText2'),
                this.$t('PlanStageBlackText3'),
                this.$t('PlanStageBlackText4'),
                this.$t('PlanStageBlackText5'),
                this.$t('PlanStageBlackText6'),
                this.$t('PlanStageBlackText7'),
                this.$t('PlanStageBlackText8'),
                this.$t('PlanStageBlackText9'),
                this.$t('PlanStageBlackText10'),
                this.$t('PlanStageBlackText11'),
                this.$t('PlanStageBlackText12'),
            ],
            lastMealTime: 0,
            username: '',
            mySwiper: {},
            startWeight: 0,
            targetWeight: 0,
            isMetric: false,
            weekInfo: [],
            planImgUrl: [
                `${this.$store.state.assetsUrl}/imgs/plan/week_img1.webp`,
                `${this.$store.state.assetsUrl}/imgs/plan/week_img2.webp`,
                `${this.$store.state.assetsUrl}/imgs/plan/week_img3.webp`,
                `${this.$store.state.assetsUrl}/imgs/plan/week_img4.webp`,
                `${this.$store.state.assetsUrl}/imgs/plan/week_img5.webp`,
            ]
        }
    },
    created() {
        this.initData();
    },
    /*
   文案展示
    第一阶段
        You will get visible results in this stage.
        You will form a good fasting habit.
        Your body will change dramatically inside.
    第二阶段
        Your weight loss in the past 4 weeks will be further boosted.
        You will keep your healthy habit and continue to drop more pounds.
    第三阶段
        You may hit a weight plateau at this stage.
        You may loss less or no weight at this stage but you still need to stay consistent with your plan.
    第四阶段
        You will break your weight loss plateau at this stage.
        You will continue to lose more weight.
    延长阶段
        You will reap more fasting benefits at the extended stage.
        You will completely adapt to the fasting lifestyle.
    如果本阶段不需要减重
        You may keep your current weight.
        You will form a healthy habit.
    */
    mounted() {
        this.mySwiper = new Swiper('.plan-swiper-container', {
            spaceBetween: 8,
            loop: false,
            slidesPerView: "auto",
            cssWidthAndHeight: true,
        })
    },
    methods: {
        initData() {
            let localList = this.$changeLocal.getItem('localList');
            if (localList) {
                let messageList = localList;
                let nameItem = messageList.filter(ite => ite.key == 'username')[0];
                if (nameItem) {
                    this.username = nameItem.value;
                }
                let lastTime = messageList.filter(ite => ite.key == 'time')[0];
                if (lastTime) {
                    let lastTimeVal = lastTime.value;
                    this.lastMealTime = lastTimeVal.split(' ')[0].split(':')[0] - 0 + (lastTimeVal.split(' ')[1] == 'PM' ? 12 : 0);
                }
                let startWeightInfo = messageList.filter(ite => ite.key == 'weight')[0];
                let targetWeightInfo = messageList.filter(ite => ite.key == 'targetWeight')[0];
                if (targetWeightInfo && startWeightInfo) {
                    if (targetWeightInfo.value.indexOf('kg') != -1) {
                        // 公制
                        this.startWeight = startWeightInfo.value.split('kg')[0];
                        this.targetWeight = targetWeightInfo.value.split('kg')[0];
                        this.isMetric = true;
                    } else {
                        // 英制
                        this.startWeight = startWeightInfo.value.split('lbs')[0];
                        this.targetWeight = targetWeightInfo.value.split('lbs')[0];
                        this.isMetric = false;
                    }
                }
            }
            this.planImgUrl.forEach(item => {
                this.planList.push({
                    imgUrl: item,
                })
            })
            /*
            第一阶段：初始体重-4kg
            第二阶段：较上次体重-5kg
            第三阶段：较上次体重-3kg
            第四阶段：较上次体重-4kg
            延长阶段：目标
            */
            let startWeight;
            let targetWeight;
            if (this.isMetric) {
                startWeight = this.startWeight;
                targetWeight = this.targetWeight;
            } else {
                startWeight = this.startWeight / common.lbs;
                targetWeight = this.targetWeight / common.lbs;
            }
            let stageIndex = 0;
            if (startWeight - targetWeight <= 4) {
                // 第一阶段有变化
                stageIndex = 1;
            } else if (startWeight - targetWeight <= 9) {
                // 第一、二阶段有变化
                stageIndex = 2;
            } else if (startWeight - targetWeight <= 12) {
                // 第一、二、三阶段有变化
                stageIndex = 3;
            } else if (startWeight - targetWeight <= 16) {
                // 第一、二、三、四阶段有变化
                stageIndex = 4;
            } else {
                // 所有阶段都有变化
                stageIndex = 5;
            }
            this.planList.forEach((item, ind) => {
                if (ind < stageIndex) {
                    item.stageTextList = this.handleBoldFont(this.stages[`stage${ind + 1}`]);
                } else {
                    item.stageTextList = this.handleBoldFont(this.stages.targetStage);
                }
                item.stageWeight = this.getStageWeight(ind);
            })
            this.getPlan();
        },
        // 获取每个阶段减重的重量
        getStageWeight(ind) {
            let stageWeight;
            switch (ind) {
                case 0:
                    stageWeight = (this.startWeight - 4 * (this.isMetric ? 1 : common.lbs)).toFixed(1);
                    break;
                case 1:
                    stageWeight = (this.startWeight - 9 * (this.isMetric ? 1 : common.lbs)).toFixed(1);
                    break;
                case 2:
                    stageWeight = (this.startWeight - 12 * (this.isMetric ? 1 : common.lbs)).toFixed(1);
                    break;
                case 3:
                    stageWeight = (this.startWeight - 16 * (this.isMetric ? 1 : common.lbs)).toFixed(1);
                    break;
                default:
                    stageWeight = this.targetWeight;
            }
            return Math.max(stageWeight, this.targetWeight);
        },
        // 处理加粗字体
        handleBoldFont(textList) {
            let stageTextList = [];
            textList.forEach(item => {
                let textArr = [];
                for (let i = 0; i < this.planBlackText.length; i++) {
                    let ite = this.planBlackText[i];
                    if (item.indexOf(ite) != - 1) {
                        textArr.push({
                            isBold: false,
                            content: item.split(ite)[0],
                        })
                        textArr.push({
                            isBold: true,
                            content: ite,
                        })
                        textArr.push({
                            isBold: false,
                            content: item.split(ite)[1],
                        })
                        break;
                    }
                }
                if (textArr.length == 0) {
                    textArr.push(
                        {
                            isBold: false,
                            content: item,
                        }
                    )
                }
                stageTextList.push(textArr);
            })
            return stageTextList;
        },
        // 获取第一周计划
        getPlan() {
            let localPlan = this.$changeLocal.getItem('localPlan');
            if (localPlan) {
                let plan = localPlan.plan;
                this.weekInfo = [];
                // 早餐、午餐
                let isBreakfastAndLunch = true;
                if (this.lastMealTime >= 11) {
                    // 午餐、晚餐
                    isBreakfastAndLunch = false;
                }
                plan.forEach(item => {
                    let mealList = [];
                    if (isBreakfastAndLunch) {
                        mealList = item.meal_plan.recipes.filter(ite => ite.category == 2 || ite.category == 1)
                    } else {
                        mealList = item.meal_plan.recipes.filter(ite => ite.category == 2 || ite.category == 3)
                    }
                    let mealInfo = {
                        fasting_hour: item.fasting_hour,
                        eating_hour: item.eating_hour,
                        cron_week_day: item.cron_week_day,
                        is_rest_day: item.is_rest_day,
                        exercise_type: item.exercise_type,
                        mealList: mealList,
                    };
                    this.weekInfo.push(mealInfo);
                })
                // if()
                this.weekInfo
            }
        },
        // 通过id获取对应的食物图
        idToMeal(id) {
            let result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_breakfast_fried_rice.webp`;
            switch (id) {
                case "62ba865ccdf7e7037d5e335b":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_breakfast_fried_rice.webp`;
                    break;
                case "62baad7fcdf7e7037d5e3372":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_kale_chicken_ceasar_salad.webp`;
                    break;
                case "62bd6a29cdf7e7037d5e3426":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_beef_chili.webp`;
                    break;
                case "62bd20efcdf7e7037d5e33f9":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_perfect_keto_breakfast.webp`;
                    break;
                case "62c3b5ecad0338d387d8595e":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_crunchy_kale_salad.webp`;
                    break;
                case "62be53eccdf7e7037d5e3438":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_stuffed_portobello_mushrooms.webp`;
                    break;
                case "62bd5358cdf7e7037d5e3402":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_breakfast_sandwich.webp`;
                    break;
                case "62babff9cdf7e7037d5e337e":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_chicken_pesto_pasta.webp`;
                    break;
                case "62bd78f6cdf7e7037d5e3435":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_butter_chicken.webp`;
                    break;
                case "62b80c3ec5bd3f9c0df0460b":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_banana_pancakes.webp`;
                    break;
                case "62c26bd5ad0338d387d8592f":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_chickpea_salad_wrap.webp`;
                    break;
                case "62bac7e3cdf7e7037d5e3388":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_asian_beef_lettuce_wraps.webp`;
                    break;
                case "62c2b4f0ad0338d387d85936":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_chocolate_pb_smoothie_bowl.webp`;
                    break;
                case "62c3eb8aad0338d387d85984":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_tufu_pad_thai.webp`;
                    break;
                case "62c3e32bad0338d387d8597b":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_noodles_lentil_meatballs.webp`;
                    break;
                case "62c2b332ad0338d387d85933":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_creamy_green_smoothie.webp`;
                    break;
                case "62bac9abcdf7e7037d5e338b":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_fish_cakes.webp`;
                    break;
                case "62baaf73cdf7e7037d5e3375":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_shrimp_fried_rice.webp`;
                    break;
                case "62c2c120ad0338d387d8593f":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_banana_bread_oatmeal_bites.webp`;
                    break;
                case "62c3e5fcad0338d387d8597e":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_mexican_stuffed_peppers.webp`;
                    break;
                case "62c3da16ad0338d387d8596f":
                    result = `${this.$store.state.assetsUrl}/imgs/plan/ic_meal_portobello_fajita_bowl.webp`;
                    break;
            }
            return result;
        }

    }
}
</script>

<style scoped lang="less">
.plan_for_you {
    position: relative;

    .plan_for_you_title {
        height: 34px;
        line-height: 34px;
        font-size: 28px;
        font-family: 'AvenirNext';
        color: #444444;
        font-weight: 700;
        margin-top: 76px;
    }

    .logo {
        position: absolute;
        top: -16px;
        left: 23px;
        width: 60px;
        height: 60px;
    }


    .plan_list {
        width: 100%;
        overflow: hidden;
        padding-top: 20px;
        margin-top: 23px;

        .plan-swiper-container {
            padding: 0 8px;

        }

        .swiper-slide {
            width: 340px;
            background: #FBFAFD;
            border-radius: 20px;
            padding-top: 20px;
            overflow: hidden;

            .title {
                font-size: 18px;
                font-family: 'AvenirNext';
                font-weight: 700;
                color: #444444;
                line-height: 24px;
            }

            .describe {
                padding: 25px 13px 17px 10px;
                display: flex;
                justify-content: space-between;

                .describe_left {
                    display: flex;
                    flex-direction: column;

                    div {
                        font-size: 14px;
                        font-family: 'AvenirNext';
                        font-weight: 500;
                        color: #5B5B68;
                        line-height: 19px;
                        position: relative;
                        padding-left: 17px;
                        width: 190px;
                        text-align: left;
                    }

                    .bold_text {
                        font-weight: bold;
                    }

                    div:after {
                        content: '';
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #FDA225;
                        left: 0;
                        top: 5px;
                    }
                }

                .describe_right {
                    position: relative;
                    height: 120px;
                    width: 90px;
                    background: #FFEEE9;
                    border-radius: 15px;
                    color: #D24F51;
                    font-size: 14px;
                    padding: 0 10px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .stage_weight {
                        font-weight: 900;
                        font-size: 18px;
                    }


                    .arrow_target {
                        position: absolute;
                        top: -20px;
                        right: -8px;
                        width: 37px;
                        height: 45px;
                    }
                }
            }

            .plan_info {
                width: 100%;
                height: 310px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .first_plan {
                    position: absolute;
                    left: 9px;
                    top: 63px;
                    width: 100%;
                    height: 188px;
                    display: flex;
                    width: 322px;
                    z-index: 3;

                    .cookbook_table {
                        flex: 1;
                        display: flex;
                        padding-top: 3px;

                        .cookbook_table_inner {
                            display: flex;
                            flex-direction: column;
                            justify-content: top;
                            align-items: center;
                        }

                        .cookbook_table_date {
                            font-size: 12px;
                            font-family: 'AvenirNext';
                            font-weight: 500;
                            color: #999999;
                            line-height: 14px;
                            margin-top: 7px;
                        }

                        .cookbook_table_fast {
                            font-size: 12px;
                            font-family: 'AvenirNext';
                            font-weight: 500;
                            color: #666666;
                            line-height: 12px;
                            margin-top: 6px;
                            height: 24px;
                        }

                        .cookbook_table_food {
                            margin: 6px auto 10px;
                            height: 70px;



                            img {
                                width: 33px;
                                height: 33px;
                                display: block;
                                margin: 3px auto 0;
                            }


                        }

                        .cookbook_table_food_rest {
                            margin-top: 3px;

                            span {
                                font-size: 13px;
                                font-family: 'AvenirNext';
                                font-weight: 600;
                                color: #444444;
                                line-height: 18px;
                                margin-top: 5px;
                                display: block;
                            }

                            img.rest {
                                width: 36px;
                                height: 36px;
                                margin-top: 0;
                            }
                        }

                        .cookbook_table_drink {
                            width: 14px;
                            height: 16px;
                            margin: 0 auto 6px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .cookbook_table_run {
                            width: 14px;
                            height: 15px;
                            margin: 0 auto;

                            img {
                                width: 100%;
                                height: 100%;

                            }
                        }

                    }

                    .cookbook_table_line {
                        width: 1px;
                        height: 126px;
                        background-color: #ddd;
                    }
                }

                .cookbook_table_describe {
                    position: absolute;
                    bottom: 24px;
                    display: flex;
                    left: 50%;
                    transform: translate(-50%, 0);
                    z-index: 3;

                    img {
                        width: 15px;
                        height: 15px;
                    }

                    span {

                        font-size: 12px;
                        font-family: 'AvenirNext';
                        font-weight: 600;
                        color: #666666;
                        line-height: 16px;
                        width: 200px;
                        text-align: left;
                    }
                }
            }
        }

    }


    img {
        pointer-events: none;
    }
}
</style>