<template>
    <div class="guarantee">
        <div v-if="showDescribe" class="guarantee_describe">
            {{ $t('GuaranteeDescribe', { oneMonth: oneMonth }) }}
        </div>
        <div class="guarantee_logo">
            <div class="guarantee_logo_day">
                <span class="day_count">30</span>
                <span>Day</span>
            </div>
            <div class="guarantee_text">
                {{ $t('Guarantee') }}
            </div>
        </div>
        <div class="money_back">
            <div class="money_back_title">
                {{ $t('MoneyBackTitle') }}
            </div>
            <div class="money_back_describe">
                <div class="money_back_describe1">
                    {{ $t('MoneyBackDescribe1') }}
                </div>
                <div class="money_back_describe2">
                    <span>
                        {{ $t('MoneyBackDescribe2') }}
                    </span>
                    <span class="money_back_describe_link" @click="$router.push('/refund')">
                        {{ $t('MoneyBackDescribeLink') }}
                    </span>
                </div>
            </div>
        </div>
        <div class="privacy_and_terms">
            <span @click="$router.push('/privacy/app')">
                {{ $t('Privacy') }},
            </span>
            <span @click="$router.push('/terms/app')">
                {{ $t('TermsOfUse') }},
            </span>
            <span @click="$router.push('/refund')">
                {{ $t('RefundPolicy') }}
            </span>
        </div>
    </div>
</template>

<script>
import { common } from '../../assets/utils/common';
export default {
    name: 'guarantee',
    props: {
        showDescribe: {
            type: Boolean,
            default: () => true,
        }
    },
    data() {
        return {

        }
    },
    computed: {
        oneMonth() {
            return common.oneMonthPrice;
        }
    }

}
</script>

<style scoped lang="less">
.guarantee {
    .guarantee_describe {
        margin: 10px 25px 60px;
        font-size: 12px;
        font-family: 'AvenirNext';
        font-weight: 600;
        color: #999999;
        line-height: 16px;
        text-align: left;
    }

    .guarantee_logo {
        width: 215px;
        height: 215px;
        background: #619AFF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;

        .guarantee_logo_day {
            display: flex;
            align-items: flex-end;

            span {
                font-size: 30px;
                font-family: 'AvenirNext';
                font-weight: 600;
                color: #FFFFFF;
            }

            span.day_count {
                font-size: 80px;
                font-family: 'AvenirNext';
                font-weight: 700;
                line-height: 65px;
            }
        }

        .guarantee_text {
            font-size: 30px;
            font-family: 'AvenirNext';
            font-weight: 600;
            color: #FFFFFF;
        }
    }

    .money_back {

        margin-top: 40px;

        .money_back_title {
            font-size: 28px;
            font-family: 'AvenirNext';
            font-weight: 700;
            color: #444444;
            text-align: left;
            margin: 0 18px;
        }

        .money_back_describe {
            div {
                font-size: 22px;
                font-family: 'AvenirNext';
                font-weight: 500;
                color: #666666;
                line-height: 30px;
                margin: 10px 18px 20px;
                text-align: left;
            }

            .money_back_describe2 {
                span.money_back_describe_link {
                    color: #609BE6;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .privacy_and_terms {
        margin-top: 40px;
        font-size: 14px;
        font-family: 'AvenirNext';
        font-weight: 600;
        color: #666666;
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>