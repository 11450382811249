<template>
  <div class="plan_is_ready" :id="elementKey == 'ready' ? 'plan_is_ready' : ''">
    <!-- 标题 -->
    <div class="title">
      {{ title }}
    </div>
    <!-- 倒计时 -->
    <div class="ready_countdown" v-if="countdownCount > 0">
      <div class="ready_countdown_describe">
        {{ $t("DiscountDescribe", { discount: discount }) }}
      </div>
      <div class="ready_countdown_time">
        <span>{{ minute }}</span> : <span>{{ second }}</span> :
        <span>{{ randomCount }}</span>
      </div>
    </div>
    <!-- 支付列表 -->
    <div class="plan_pay_list">
      <div
        class="plan_pay_item"
        @click="selectIndex = ind"
        :class="{
          active: selectIndex == ind,
          most_popular: mostPopularIndex == ind && selectIndex == ind,
        }"
        v-for="(item, ind) in priceList"
        :key="ind"
      >
        <div class="plan_pay_item_time">
          {{ item.title }}
        </div>
        <div class="plan_pay_item_cost">
          <div class="cost">
            <span>${{ item | checkPrice }}</span>
            <span class="unit">/day</span>
          </div>
          <!-- 产品约定：第一条不展示折扣 -->
          <div v-if="ind != 0" class="discount">
            ${{ (oneMonth / 30).toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
    <!-- 30天退款 -->
    <div class="plan_30_day_wrap">
      <div class="plan_30_day" @click="$router.push('/refund')">
        {{ $t("30Day") }}
      </div>
    </div>
    <!-- 按钮 -->
    <spread-animation
      v-if="priceList.length && paypal.Buttons"
      :text="$t('BuyProNormal_Continue_ItemTitle')"
      :width="'100%'"
      :height="'55px'"
      :startColor="'#F77976 0%'"
      :endColor="'#FEBA4D 99%'"
      :callback="openPop"
    ></spread-animation>

    <!-- 支付模块 -->
    <van-popup
      :lazy-render="false"
      :close-on-click-overlay="false"
      v-model="showPayModel"
      round
      :position="isWide ? 'center' : 'bottom'"
    >
      <div :class="isWide ? 'web_pop' : 'pop'">
        <div class="pop_close" @click="closePop">
          <img
            @error="imageError"
            :src="`${$store.state.assetsUrl}/imgs/plan/close_button.webp`"
            alt="close"
          />
        </div>
        <div class="pop_title">
          {{ $t("PayTitle") }}
        </div>
        <div class="pop_choose">
          <img
            @error="imageError"
            :src="`${$store.state.assetsUrl}/imgs/plan/paypal_logo.webp`"
            alt="pop logo"
          />
        </div>
        <div class="subscription_info">
          <div class="subscription_original">
            <div class="subscription_original_left">
              {{ selectPayInfo.title }}
            </div>
            <div class="subscription_original_right">
              ${{
                selectIndex != 0 ? totalPrice : selectPayInfo.first_price / 100
              }}
            </div>
          </div>
          <!-- 产品约定：第一条不展示折扣 -->
          <div
            class="subscription_discount"
            v-if="
              oneMonth * selectPayInfo.value !=
                selectPayInfo.first_price / 100 && selectIndex != 0
            "
          >
            <div class="subscription_discount_left">
              {{ selectDiscount }} price discount
            </div>
            <div class="subscription_discount_right">-${{ discountPrice }}</div>
          </div>
        </div>
        <div class="total">
          <div class="total_label">
            {{ $t("BuyProFlashOffer_Total_ItemTitle") }}:
          </div>
          <div class="total_discount">
            <div class="total_discount_top">
              <span class="weight_bold" style="color: #444"
                >${{ (selectPayInfo.first_price / 100).toFixed(2) }}
              </span>
              <span>per {{ timeValue }}</span>
            </div>
            <!-- 产品约定：第一条不展示折扣 -->
            <div class="total_discount_bottom" v-if="selectIndex != 0">
              <span>You just saved</span>
              <span class="weight_bold"> ${{ discountPrice }}</span>
              <span>( {{ selectDiscount }} off)</span>
            </div>
          </div>
        </div>
        <div class="describe">
          Subscriptions will automatically renew unless it is canceled at least
          24 hours before the end of the current billing period. You can manage
          subscription via paypal any time after purchase.
        </div>
        <div class="paypal">
          <div :id="`paypal-button-container-${elementKey}`"></div>
        </div>
      </div>
    </van-popup>
    <!-- 取消支付后的弹窗 -->
    <van-popup
      :close-on-click-overlay="false"
      v-model="showCancelModel"
      round
      :position="isWide ? 'center' : 'bottom'"
    >
      <div :class="isWide ? 'cancel_web_pop' : 'cancel_pop'">
        <div class="cancel_pop_title">{{ $t("CancelTitle") }}</div>
        <div class="cancel_pop_describe">{{ $t("CancelDescribe") }}</div>
        <div class="cancel_pop_weight">
          <img
            @error="imageError"
            :src="`${$store.state.assetsUrl}/imgs/plan/fasting.webp`"
            alt="weight timeline"
          />
          <div>
            <span>*</span>
            <span
              >Based on data from users who log their progress in the app</span
            >
          </div>
        </div>
        <div class="cancel_pop_tips">
          <div class="cancel_pop_tips_lamp">💡</div>
          <div class="cancel_pop_tips_text">{{ $t("CancelTips") }}</div>
        </div>
        <div class="cancel_pop_button" @click="closeCancelPop">
          {{ $t("GotIt") }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { sendSubscriptionInfo, subscriptionsSuccessful } from "@/api/index.js";
import { mapState } from "vuex";
import SpreadAnimation from "../spread_animation.vue";

import Vue from "vue";
import { Toast } from "vant";
import { myMixin } from "@/assets/mixin/index.js";
import { commonMixin } from "@/assets/mixin/common";
import { logFirebaseEvent } from "@/assets/log/log_manager.js";
import { common } from "../../assets/utils/common";
Vue.use(Toast);
export default {
  name: "plan_is_ready",
  components: { SpreadAnimation },
  mixins: [myMixin, commonMixin],
  props: {
    mostPopularIndex: {
      type: Number,
      default: () => 0,
    },
    discount: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    elementKey: {
      type: String,
      default: () => "ready",
    },
    paypal: {
      type: Object,
      default: () => {},
    },
    priceList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showCancelModel: false,
      wideShowCancel: false,
      narrowShowCancel: false,
      showPayModel: false,
      wideShowPay: false,
      narrowShowPay: false,
      isWide: false,
      randomTimer: null,
      countdownTimer: null,
      random: 0,
      showTimer: true,
      selectIndex: 0,
      selectDiscount: 0,
      discountPrice: 0,
    };
  },
  filters: {
    checkPrice(item) {
      if (item.value_type == 0) {
        // 当类型为0时value是月份
        // 每天的价格（美元）= 美分 / 100 / 多少个月 / 30天（默认一个月30天）
        return (item.first_price / 100 / item.value / 30).toFixed(2);
      } else if (item.value_type == 1) {
        // 当类型为0时value是天数
        // 每天的价格（美元）= 美分 / 100 / 多少天
        return (item.first_price / 100 / item.value).toFixed(2);
      }
    },
  },
  created() {},
  mounted() {
    this.listenWidth();
  },
  methods: {
    // 关闭取消弹窗
    closeCancelPop() {
      this.showCancelModel = false;
      if (this.pageType != "pageB") {
        this.$store.dispatch("setCountdownCount", "600");
        this.goPath("/plan-1");
      }
    },
    // 打开支付弹窗
    openPop() {
      this.showPayModel = true;
      // 拉起支付窗口的埋点
      logFirebaseEvent("F_Web_Pro_PayWindow", {
        type: `${this.pageType == "pageB" ? "B" : "A"}${this.selectIndex + 1}`,
      });
      this.initPatPal();
    },
    // 关闭支付弹窗
    closePop() {
      this.showPayModel = false;
      if (this.pageType != "pageB") {
        this.showCancelModel = true;
      }
    },
    // 重新定义是否是宽屏
    resetWidth() {
      this.isWide = document.body.offsetWidth >= 765;
    },
    // 监听屏幕宽度变化
    listenWidth() {
      this.resetWidth();
      window.addEventListener("resize", this.resetWidth, { passive: true });
    },
    // 初始化paypal
    initPatPal() {
      if (
        !document.getElementById(`paypal-button-container-${this.elementKey}`)
      )
        return;
      // 每次点击按钮都初始化
      document.getElementById(
        `paypal-button-container-${this.elementKey}`
      ).innerHTML = "";
      this.paypal
        .Buttons({
          style: {
            shape: "pill",
            color: "gold",
            layout: "vertical",
            label: "subscribe",
          },
          createSubscription: async (_, actions) => {
            let actionData = await actions.subscription.create({
              plan_id: this.selectPayInfo.sku,
            });
            let res = await sendSubscriptionInfo({
              subscription_id: actionData,
            });
            if (res.errorCode) {
              if (res.errorCode == 4053 || res.errorCode == 5005) {
                this.goPath("/subscribe-done");
                return false;
              } else {
                Toast(this.$t("NetworkErrorToast"));
                return;
              }
            }

            // [selectPayInfo]中，[value_type]为0时是月，为1时是天
            // [product_catalog_id]字段
            // 7天的就是"sub-7d",
            // 1个月的"sub-1m",
            // 3个月的"sub-3m"
            let product_catalog_id = "";
            if (this.selectPayInfo.value_type == 1) {
              product_catalog_id = "sub-7d";
            } else if (this.selectPayInfo.value == 1) {
              product_catalog_id = "sub-1m";
            } else {
              product_catalog_id = "sub-3m";
            }
            // FB点击支付的埋点
            window.fbq("track", "InitiateCheckout", {
              content_ids: [this.selectPayInfo.sku],
              currency: "USD",
              value: parseFloat(
                (this.selectPayInfo.first_price / 100).toFixed(2)
              ),
              content_type: "product",
              product_catalog_id,
            });
            return actionData;
          },
          onApprove: (data) => {
            // 支付成功的埋点
            logFirebaseEvent("F_Web_Pro_Paid", {
              type: `${this.pageType == "pageB" ? "B" : "A"}${
                this.selectIndex + 1
              }`,
            });
            // [selectPayInfo]中，[value_type]为0时是月，为1时是天
            // [product_catalog_id]字段
            // 7天的就是"sub-7d",
            // 1个月的"sub-1m",
            // 3个月的"sub-3m"
            let product_catalog_id = "";
            if (this.selectPayInfo.value_type == 1) {
              product_catalog_id = "sub-7d";
            } else if (this.selectPayInfo.value == 1) {
              product_catalog_id = "sub-1m";
            } else {
              product_catalog_id = "sub-3m";
            }
            // FB点击支付成功的埋点 测试
            window.fbq("track", "Purchase", {
              content_ids: [this.selectPayInfo.sku],
              currency: "USD",
              value: parseFloat(
                (this.selectPayInfo.first_price / 100).toFixed(2)
              ),
              content_type: "product",
              product_catalog_id,
            });
            Toast.success(this.$t("SubscriptionSuccessful"));
            subscriptionsSuccessful({
              subscription_id: data.subscriptionID,
              order_id: data.orderID,
            });
            this.goPath("/subscribe-done");
          },
          onError(e) {
            console.log(e);
          },
          onCancel() {
            console.log("cancel");
          },
        })
        .render(`#paypal-button-container-${this.elementKey}`);
    },
  },
  watch: {
    priceList() {
      this.selectIndex = this.mostPopularIndex;
    },
    selectPayInfo(val) {
      this.selectDiscount = common.getDiscount(val);
      this.discountPrice = common.getDiscountPrice(val);
    },
  },
  computed: {
    timeValue() {
      if (this.selectPayInfo.value_type == 0) {
        // 当类型为0时value是月份
        return `${this.selectPayInfo.value} ${this.timeUnit}`;
      } else if (this.selectPayInfo.value_type == 1) {
        // 当类型为0时value是天数
        return `${this.selectPayInfo.value} ${this.timeUnit}`;
      }
      return "";
    },
    timeUnit() {
      if (this.selectPayInfo.value_type == 0) {
        // 当类型为0时value是月份
        return this.selectPayInfo.value == 1 ? "month" : "months";
      } else if (this.selectPayInfo.value_type == 1) {
        // 当类型为0时value是天数
        return this.selectPayInfo.value == 1 ? "day" : "days";
      }
      return "";
    },
    totalPrice() {
      if (this.selectPayInfo.value_type == 0) {
        // 当类型为0时value是月份
        // 每个月的金额 * 多少个月
        return (this.oneMonth * this.selectPayInfo.value).toFixed(2);
      } else if (this.selectPayInfo.value_type == 1) {
        // 当类型为0时value是天数
        // 每个月的金额 / 30天 * 多少天
        return ((this.oneMonth / 30) * this.selectPayInfo.value).toFixed(2);
      }
      return "";
    },
    oneMonth() {
      return common.oneMonthPrice;
    },
    pageType() {
      let pageType = "pageA";
      if (this.$route.name == "plan-1") {
        pageType = "pageB";
      }
      return pageType;
    },
    selectPayInfo: {
      get() {
        if (this.priceList.length) {
          return this.priceList[this.selectIndex];
        }
        return {};
      },
      set() {},
    },
    ...mapState(["countdownCount", "randomCount"]),
    minute: {
      get() {
        return this.$store.getters.minute;
      },
      set() {},
    },
    second: {
      get() {
        return this.$store.getters.second;
      },
      set() {},
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.resetWidth);
  },
};
</script>

<style scoped lang="less">
.plan_is_ready {
  margin: 0 16px;
  margin-top: 42px;

  .title {
    font-size: 28px;
    font-family: "AvenirNext";
    font-weight: 700;
    color: #444444;
    line-height: 38px;
    text-align: left;
    margin-bottom: 20px;
  }

  .ready_countdown {
    font-size: 14px;
    font-family: "AvenirNext";
    font-weight: 500;
    color: rgba(68, 68, 68, 0.8);
    line-height: 20px;
    display: flex;
    align-items: center;

    .ready_countdown_time {
      margin-left: 5px;
      font-size: 16px;
      font-family: "AvenirNext";
      font-weight: 500;
      line-height: 18px;
      color: #444444;

      span {
        display: inline-block;
        width: 26px;
        height: 26px;
        background: #444444;
        border-radius: 5px;
        color: #fff;
        line-height: 26px;
      }
    }
  }

  .plan_pay_list {
    padding-top: 1px;

    .plan_pay_item {
      display: flex;
      height: 60px;
      background: #ffffff;
      border-radius: 12px;
      border: 2px solid #efefef;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-top: 10px;
      padding: 0 15px;

      .plan_pay_item_time {
        font-size: 16px;
        font-family: "AvenirNext";
        font-weight: 700;
        color: rgba(0, 0, 0, 0.7);
        line-height: 26px;
      }

      .plan_pay_item_cost {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .cost {
          font-size: 20px;
          font-family: "AvenirNext";
          font-weight: 700;
          color: #444444;
          line-height: 27px;

          .unit {
            font-size: 12px;
            font-family: "AvenirNext";
            font-weight: 600;
            color: #444444;
            line-height: 16px;
          }
        }

        .discount {
          font-size: 12px;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #666666;
          line-height: 16px;
          display: flex;
          justify-content: flex-end;
          text-decoration: line-through;
        }
      }
    }

    .active {
      margin-top: 21px;
      background: #fff3f0;
      border-radius: 12px;
      height: 66px;
      border: 2px solid #fd6169;
      position: relative;

      .plan_pay_item_cost {
        .cost {
          font-size: 24px;
          font-family: "AvenirNext";
          font-weight: 700;
          color: #fd6169;
          line-height: 33px;

          .unit {
            color: #fd6169;
          }
        }
      }
    }

    .most_popular::after {
      content: "Most Popular";
      font-size: 12px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 105px;
      height: 25px;
      background: #fd6169;
      border-radius: 13px 0px 13px 0px;
      top: 0;
      left: -2px;
      transform: translate(0%, -50%);
    }
  }

  .plan_30_day_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .plan_30_day {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: "AvenirNext";
    font-weight: 600;
    color: #666666;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }

  .pop {
    position: relative;
    padding: 0 18px 100px;

    .pop_close {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 16px;
      top: 16px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .pop_title {
      font-size: 20px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #333333;
      line-height: 27px;
      padding-top: 54px;
    }

    .pop_choose {
      width: 165px;
      height: 85px;
      border-radius: 12px;
      margin: 20px auto 30px;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .subscription_info {
      padding: 15px 0;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;

      .subscription_original {
        display: flex;
        justify-content: space-between;

        .subscription_original_left {
          font-size: 14px;
          font-family: "AvenirNext";
          font-weight: 400;
          color: #7c8499;
          line-height: 19px;
        }

        .subscription_original_right {
          font-size: 14px;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #7c8499;
          line-height: 19px;
        }
      }

      .subscription_discount {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;

        .subscription_discount_left {
          font-size: 14px;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #7c8499;
          line-height: 19px;
        }

        .subscription_discount_right {
          font-size: 14px;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #fd7c61;
          line-height: 19px;
        }
      }
    }

    .total {
      margin-top: 19px;
      display: flex;
      justify-content: space-between;

      .total_label {
        font-size: 16px;
        font-family: "AvenirNext";
        font-weight: 600;
        color: #444444;
        line-height: 22px;
      }

      .total_discount {
        display: flex;
        flex-direction: column;

        .total_discount_top {
          text-align: right;
          margin-bottom: 3px;
          font-size: 14px;
          font-family: "AvenirNext";
          font-weight: 600;
          color: #666;
          line-height: 19px;
        }

        .total_discount_bottom {
          font-size: 14px;
          font-family: "AvenirNext";
          font-weight: 600;
          color: #fd7c61;
          line-height: 19px;
        }

        .weight_bold {
          font-weight: 900;
          font-size: 16px;
        }
      }
    }

    .describe {
      font-size: 12px;
      font-family: "AvenirNext";
      font-weight: 500;
      color: #888888;
      line-height: 16px;
      margin-top: 18px;
    }

    .paypal {
      margin-top: 21px;
    }
  }

  .web_pop {
    position: relative;
    padding: 0 24px 49px;
    width: 500px;
    border-radius: 25px;

    .pop_close {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 20px;
      top: 20px;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .pop_title {
      padding-top: 72px;
      font-size: 28px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #333333;
      line-height: 38px;
    }

    .pop_choose {
      margin: 24px auto 40px;
      width: 220px;
      height: 114px;
      border-radius: 16px;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .subscription_info {
      padding: 20px 0;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;

      .subscription_original {
        display: flex;
        justify-content: space-between;

        .subscription_original_left {
          font-size: 18px;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #7c8499;
          line-height: 25px;
        }

        .subscription_original_right {
          font-size: 18px;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #7c8499;
          line-height: 25px;
        }
      }

      .subscription_discount {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;

        .subscription_discount_left {
          font-size: 18px;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #7c8499;
          line-height: 25px;
        }

        .subscription_discount_right {
          font-size: 18px;
          font-family: "AvenirNext";
          font-weight: 500;
          color: #fd7c61;
          line-height: 25px;
        }
      }
    }

    .total {
      margin-top: 27px;
      display: flex;
      justify-content: space-between;

      .total_label {
        font-size: 21px;
        font-family: "AvenirNext";
        font-weight: 600;
        color: #444444;
        line-height: 29px;
      }

      .total_discount {
        display: flex;
        flex-direction: column;

        .total_discount_top {
          text-align: right;
          margin-bottom: 3px;
          font-size: 18px;
          font-family: "AvenirNext";
          font-weight: 600;
          color: #444444;
          line-height: 25px;
        }

        .total_discount_bottom {
          font-size: 18px;
          font-family: "AvenirNext";
          font-weight: 600;
          color: #fd7c61;
          line-height: 25px;
        }

        .weight_bold {
          font-weight: 900;
          font-size: 22px;
        }
      }
    }

    .describe {
      font-size: 14px;
      font-family: "AvenirNext";
      font-weight: 500;
      color: #888888;
      line-height: 19px;
      margin-top: 27px;
    }

    .paypal {
      margin-top: 28px;
    }
  }

  .cancel_pop {
    padding: 25px 16px 100px;
    text-align: left;

    .cancel_pop_title {
      font-size: 20px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #333333;
      line-height: 27px;
      text-align: center;
    }

    .cancel_pop_describe {
      font-size: 14px;
      font-family: "AvenirNext";
      font-weight: 400;
      color: #444444;
      line-height: 19px;
      text-align: center;
      margin-top: 10px;
    }

    .cancel_pop_weight {
      margin: 20px 22px;

      img {
        width: 100%;
      }

      div {
        margin-top: 10px;
        display: flex;
        font-size: 12px;
        font-family: "AvenirNext";
        font-weight: 500;
        color: #cfcfcf;
        line-height: 15px;

        span:nth-child(1) {
          margin-right: 2px;
        }
      }
    }

    .cancel_pop_tips {
      background: #fffaee;
      border-radius: 20px;
      display: flex;
      padding: 16px 20px;

      .cancel_pop_tips_lamp {
        font-size: 16px;
        font-family: AppleColorEmoji;
        color: #666666;
        margin-top: -8px;
        line-height: 26px;
      }

      .cancel_pop_tips_text {
        font-size: 14px;
        font-family: "AvenirNext";
        font-weight: 600;
        color: rgba(51, 51, 51, 0.7);
        line-height: 19px;
      }
    }

    .cancel_pop_button {
      background: #fd7c61;
      margin-top: 33px;
      border-radius: 55px;
      height: 55px;
      font-size: 16px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #ffffff;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .cancel_web_pop {
    padding: 32px 24px 34px;
    width: 500px;
    text-align: left;
    border-radius: 25px;

    .cancel_pop_title {
      font-size: 28px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #333333;
      line-height: 38px;
      text-align: center;
    }

    .cancel_pop_describe {
      font-size: 20px;
      font-family: "AvenirNext";
      font-weight: 400;
      color: #444444;
      line-height: 27px;
      text-align: center;
      margin-top: 10px;
    }

    .cancel_pop_weight {
      margin: 24px 25px;

      img {
        width: 100%;
      }

      div {
        margin-top: 9px;
        display: flex;
        font-size: 14px;
        font-family: "AvenirNext";
        font-weight: 500;
        color: #cfcfcf;
        line-height: 19px;

        span:nth-child(1) {
          margin-right: 2px;
        }
      }
    }

    .cancel_pop_tips {
      background: #fffaee;
      border-radius: 25px;
      display: flex;
      padding: 20px 24px;

      .cancel_pop_tips_lamp {
        font-size: 22px;
        font-family: AppleColorEmoji;
        color: #666666;
        line-height: 30px;
        margin-top: -5px;
      }

      .cancel_pop_tips_text {
        font-size: 20px;
        font-family: "AvenirNext";
        font-weight: 600;
        color: rgba(51, 51, 51, 0.7);
        line-height: 27px;
      }
    }

    .cancel_pop_button {
      margin-top: 40px;
      border-radius: 74px;
      height: 74px;
      background: #fd7c61;
      font-size: 21px;
      font-family: "AvenirNext";
      font-weight: 600;
      color: #ffffff;
      line-height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  img {
    pointer-events: none;
  }
}
</style>
