<template>
    <div class="discount_countdown" v-if="countdownCount > 0">
        <div class="discount_countdown_timer">
            <div class="describe">
                {{ $t('DiscountDescribe', { discount: discount }) }}
            </div>
            <div class="timer">
                {{ minute }}:{{ second }}:{{ randomCount }}
            </div>
        </div>
        <spread-animation :text="$t('BuyProNormal_Continue_ItemTitle')" :width="'100px'" :height="'37px'"
            :startColor="'#FFA25F'" :endColor="'#FFA25F'" :callback="goPlan"></spread-animation>
    </div>
</template>

<script>
import SpreadAnimation from '../spread_animation.vue';
import { mapState } from 'vuex';
export default {
    name: 'countdown',
    components: { SpreadAnimation },
    props: {
        discount: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {}
    },
    created() {
    },
    methods: {
        goPlan() {
            document.getElementById('plan_is_ready').scrollIntoView({ behavior: "smooth", block: 'center' });
        }
    },
    watch: {},
    computed: {
        ...mapState([
            'countdownCount', 'randomCount'
        ]),
        minute: {
            get() {
                return this.$store.getters.minute;
            },
            set() { }
        },
        second: {
            get() {
                return this.$store.getters.second;
            },
            set() { }
        }
    },
}
</script>

<style scoped lang="less">
.discount_countdown {
    margin: 20px 16px;
    height: 65px;
    background-image: linear-gradient(to Right, #E1CCFF, #FFFAC0);
    box-shadow: 0px 1px 6px 0px rgba(73, 15, 15, 0.2);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    box-sizing: border-box;
    align-items: center;

    .discount_countdown_timer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .describe {
            font-size: 12px;
            font-family: 'AvenirNext';
            font-weight: 500;
            color: rgba(68, 68, 68, 0.8);
            line-height: 16px;
            margin-top: 2px;
        }

        .timer {
            font-size: 24px;
            font-family: 'AvenirNext';
            font-weight: 800;
            color: #F12C2C;
            text-align: left;
            line-height: 24px;
            margin-top: 6px;
        }
    }
}
</style>