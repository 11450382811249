<template>
    <div class="comment_list">
        <div class="comment_title">
            {{ $t('CommentTitle') }}
        </div>
        <div class="comment_content">
            <loading-image :imageUrl="`${$store.state.assetsUrl}/imgs/plan/comment.webp`"
                :min-height="'300px'"></loading-image>
        </div>
    </div>
</template>

<script>
import LoadingImage from '../loading_image.vue';
export default {
    components: { LoadingImage }
}
</script>

<style scoped lang="less">
.comment_list {
    margin-top: 40px;

    .comment_title {
        font-size: 28px;
        font-family: 'AvenirNext';
        font-weight: 700;
        color: #444444;
        line-height: 38px;
        text-align: left;
        padding: 0 18px;
    }

    .comment_content {
        .comment {
            width: 100%;
        }
    }


}
</style>